<template>
  <div>
    <div class="container">
      <PageIntro>
        <template v-slot:title>
          {{ $t("components.menus.addNewDish") }}
        </template>
        <template v-slot:description>
          {{ $t("components.menus.addNewDishDescription") }}
        </template>
      </PageIntro>
    </div>
    <div class="sm:container">
      <DishForm :current-dish="dish">
        <template v-slot:dishFormBottom>
          <BaseButton
            size="medium"
            variant="fill"
            full-width
            :text="$t(`components.menus.saveAndPublishDish`)"
            :disabled="!isFormValid"
            @buttonClick="saveNewDish"
          >
          </BaseButton>
          <BaseButton
            size="medium"
            variant="outline"
            full-width
            :text="$t(`global.cancel`)"
            @buttonClick="redirectToPreviousPage"
          >
          </BaseButton>
        </template>
      </DishForm>
    </div>
  </div>
</template>

<script>
import PageIntro from "@/components/menus/PageIntro";
import DishForm from "@/components/menus/DishForm";

import { mapActions, mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    PageIntro,
    DishForm,
  },

  props: {
    prefillBasicInformation: {
      type: Object,
      default: () => {
        return {
          name: "",
          description: "",
          courseUuid: "",
          coverImage: {
            uuid: "",
            url: null,
          },
        };
      },
    },
  },

  beforeRouteEnter(_to, _from, next) {
    Promise.all([
      store.dispatch("dish/fetchDishCourses"),
      store.dispatch("dishForm/resetForm"),
    ]).finally(() => {
      next();
    });
  },

  data() {
    return {
      dish: {
        name: this.prefillBasicInformation.name,
        description: this.prefillBasicInformation.description,
        servings: "",
        prepTime: "",
        course: {
          uuid: this.prefillBasicInformation.courseUuid,
        },
        coverImageFileUuid: this.prefillBasicInformation.coverImage.uuid,
        ingredients: [],
        preparation: [],
        assembly: [],
        coverImage: this.prefillBasicInformation.coverImage,
        preparationVideo: null,
      },
    };
  },

  computed: {
    ...mapGetters("dishForm", ["isFormValid"]),
  },

  methods: {
    ...mapActions("dishForm", ["saveNewDish"]),

    redirectToPreviousPage() {
      this.$router.go(-1);
    },
  },
};
</script>
